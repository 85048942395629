.bg {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.9);
  position: fixed;
  z-index: 998;
  top: 0;
}

.forgot {
  background-color: white;
  box-shadow: 0px 0px 8px 8px aqua;
  border-radius: 20px;
  padding: 50px 30px;
  width: 50%;
  position: fixed;
  left: 50%;
  top: 50%;
  max-height: 90%;
  overflow: scroll;
  z-index: 999;
  &__top {
    display: flex;
    justify-content: space-between;
    &__back {
      padding: 10px;
      border-radius: 5px;
      background-color: white;
      color: black;
      border: 2px solid orange;
      cursor: pointer;
      transition: all 400ms;
      &:hover {
        color: white;
        background-color: orange;
        transform: scale(1.05);
      }
    }
    &__close {
      cursor: pointer;
      border: none;
      background-color: orange;
      border-radius: 5px;
      transition: all 400ms;
      padding: 5px;
      &:hover {
        transform: scale(1.05);
      }
      &:hover > &__img {
        transform: rotate(90deg);
      }
      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 400ms;
      }
    }
  }
  &__h1 {
    color: black;
    margin: 40px 0px;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  &__form {
    display: flex;
    flex-direction: column;
    &__submit {
      margin: 30px auto 20px auto;
      text-align: center;
      &__btn {
        cursor: pointer;
        border: 2px solid orange;
        padding: 10px;
        background-color: white;
        color: black;
        border-radius: 10px;
        margin-top: 20px;
        transition: all 400ms;
        &:hover {
          color: white;
          background-color: orange;
          transform: scale(1.05);
        }
        &__load {
          border-radius: 10px;
          border: 2px solid orange;
          background-color: white;
          padding: 0px 10px;
          display: flex;
          margin-top: 20px;
          color: black;
          &__span {
            align-self: center;
          }
          &__arc {
            position: relative;
            bottom: 9px;
            margin: 18px 10px 0px 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border-top: 1px solid black;
            border-bottom: 1px solid transparent;
            border-left: 1px solid black;
            border-right: 1px solid transparent;
            animation: load 2000ms both infinite ease-out;
            &__circle {
              position: relative;
              right: -13px;
              width: 5px;
              height: 5px;
              background-color: black;
              border-radius: 50%;
            }
          }
        }
      }
      &__resend {
        margin: auto;
        text-align: center;
        &__btn {
          cursor: pointer;
          border: 2px solid orange;
          padding: 10px;
          background-color: white;
          color: black;
          border-radius: 10px;
          transition: all 400ms;
          &:hover {
            transform: scale(1.05);
          }
          &__load {
            border-radius: 10px;
            border: 2px solid orange;
            background-color: white;
            padding: 0px 10px;
            display: flex;
            margin-top: 20px;
            color: black;
            margin: 20px auto 0px auto;
            &__span {
              align-self: center;
            }
            &__arc {
              position: relative;
              bottom: 9px;
              margin: 18px 10px 0px 10px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border-top: 1px solid black;
              border-bottom: 1px solid transparent;
              border-left: 1px solid black;
              border-right: 1px solid transparent;
              animation: load 2000ms both infinite ease-out;
              &__circle {
                position: relative;
                right: -13px;
                width: 5px;
                height: 5px;
                background-color: black;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
    &__input {
      &__remember {
        margin-left: 8px;
        position: relative;
        top: 2px;
      }
      &__error {
        margin-top: 5px;
        color: red;
      }
    }
    &__errors {
      margin-top: 5px;
      color: red;
    }
  }
}

@media screen and (max-width: 768px) {
  .forgot {
    width: 80%;
    padding: 40px 20px;
  }
}

@media screen and (max-width: 425px) {
  .forgot {
    width: 95%;
    padding: 40px 10px;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}

.bg {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.9);
  position: fixed;
  z-index: 700;
  top: 0;
}

.modalDeleteAccount {
  background-color: white;
  box-shadow: 0px 0px 8px 8px aqua;
  border-radius: 20px;
  padding: 50px 30px;
  width: 50%;
  position: fixed;
  left: 50%;
  top: 50%;
  max-height: 90%;
  overflow: scroll;
  z-index: 999;
  &__h1 {
    color: black;
    margin: 40px 0px;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  &__btn {
    cursor: pointer;
    float: right;
    position: absolute;
    right: 28px;
    top: 25px;
    border: none;
    background-color: orange;
    border-radius: 5px;
    transition: all 400ms;
    padding: 5px;
    &:hover {
      transform: scale(1.05);
    }
    &:hover > &__img {
      transform: rotate(90deg);
    }
    &__img {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 400ms;
    }
  }
  &__p {
    margin-top: 20px;
  }
  &__div {
    display: flex;
    flex-direction: column;
    &__form {
      &__div {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        &:not(:focus-within) .modalDeleteAccount__div__form__div__label__value {
          transform: translateY(-13px) scale(0.75);
          transform-origin: top left;
          color: black;
        }
        &__label {
          color: black;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          position: absolute;
          z-index: 0;
          transform: translateY(6px) scale(1);
          transition: all 0.3s;
          &__value {
            color: #1976d2;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            position: absolute;
            z-index: 0;
            transform: translateY(-13px) scale(0.75);
            transform-origin: top left;
            transition: all 0.3s;
          }
        }
        &__div {
          width: 100%;
          position: relative;
          &__select {
            /* -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            padding: 5px;*/
            font: inherit;
            position: relative;
            z-index: 2;
            background-color: transparent;
            cursor: pointer;
            padding: 4px 0 5px;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            width: 100%;
            border: none;
            outline: none;
            box-sizing: content-box;
            height: 1.4375em;
            border-bottom: 2px solid rgba(0, 0, 0, 0.42);
            &:hover {
              border-bottom: 2px solid black;
            }
          }
          &__img {
            position: absolute;
            z-index: 3;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            transition: all 4000ms;
          }
        }
        &__error {
          color: red;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-size: 0.75rem;
          line-height: 1.66;
          letter-spacing: 0.03333em;
          margin-top: 3px;
          margin-bottom: -3px;
        }
        &__btn {
          text-align: center;
        }
      }
    }
  }
  &__btn {
    &__delete {
      cursor: pointer;
      margin: 30px 0px 20px 0px;
      border-radius: 10px;
      border: 2px solid red;
      background-color: white;
      padding: 10px;
      color: black;
      transition: all 400ms;
      &:hover {
        background-color: red;
        transform: scale(1.05);
        color: white;
      }
      &__load {
        margin: 30px auto 20px auto;
        border-radius: 10px;
        border: 2px solid red;
        background-color: white;
        padding: 0px 10px;
        color: black;
        display: flex;
        justify-content: center;
        color: black;
        &__span {
          align-self: center;
        }
        &__arc {
          position: relative;
          bottom: 9px;
          margin: 18px 10px 0px 10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border-top: 1px solid black;
          border-bottom: 1px solid transparent;
          border-left: 1px solid black;
          border-right: 1px solid transparent;
          animation: load 2000ms both infinite ease-out;
          &__circle {
            position: relative;
            right: -13px;
            width: 5px;
            height: 5px;
            background-color: black;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .modalDeleteAccount {
    width: 95%;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}

.bg {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.9);
  position: fixed;
  z-index: 700;
  top: 0;
}

.modalEditPasswordData {
  background-color: white;
  box-shadow: 0px 0px 8px 8px aqua;
  border-radius: 20px;
  padding: 50px 30px;
  width: 50%;
  position: fixed;
  left: 50%;
  top: 50%;
  max-height: 90%;
  overflow: scroll;
  z-index: 999;
  &__h1 {
    color: black;
    margin: 40px 0px;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  &__btn {
    cursor: pointer;
    float: right;
    position: absolute;
    right: 28px;
    top: 25px;
    border: none;
    background-color: orange;
    border-radius: 5px;
    transition: all 400ms;
    padding: 5px;
    &:hover {
      transform: scale(1.05);
    }
    &:hover > &__img {
      transform: rotate(90deg);
    }
    &__img {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 400ms;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    &__group {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      &__label {
        color: black;
        margin-bottom: 5px;
      }
      &__input {
        border-radius: 5px;
      }
      &__error {
        color: red;
        margin: 5px 0px -5px 0px;
      }
    }
    &__submit {
      text-align: center;
      margin: 50px auto 20px auto;
      &__btn {
        cursor: pointer;
        border-radius: 10px;
        border: 2px solid orange;
        background-color: white;
        padding: 10px;
        color: black;
        transition: all 400ms;
        &:hover {
          background-color: orange;
          transform: scale(1.05);
          color: white;
        }
        &__load {
          border-radius: 10px;
          border: 2px solid orange;
          background-color: white;
          padding: 0px 10px;
          display: flex;
          color: black;
          &__span {
            align-self: center;
          }
          &__arc {
            position: relative;
            bottom: 9px;
            margin: 18px 10px 0px 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border-top: 1px solid black;
            border-bottom: 1px solid transparent;
            border-left: 1px solid black;
            border-right: 1px solid transparent;
            animation: load 2000ms both infinite ease-out;
            &__circle {
              position: relative;
              right: -13px;
              width: 5px;
              height: 5px;
              background-color: black;
              border-radius: 50%;
            }
          }
        }
      }
    }
    &__errors {
      margin-top: 5px;
      color: red;
    }
  }
}

@media screen and (max-width: 768px) {
  .modalEditPasswordData {
    width: 80%;
    padding: 40px 20px;
    &__btn {
      right: 20px;
      top: 22px;
    }
  }
}

@media screen and (max-width: 425px) {
  .modalEditPasswordData {
    width: 95%;
    padding: 40px 10px;
    &__btn {
      right: 14px;
      top: 18px;
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}

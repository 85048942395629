.bg {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.9);
  position: fixed;
  z-index: 900;
  top: 0;
}

.modalCloseEmail {
  background-color: white;
  box-shadow: 0px 0px 8px 8px aqua;
  border-radius: 20px;
  padding: 50px 30px;
  width: 50%;
  position: fixed;
  margin: auto;
  left: 50%;
  top: 50%;
  max-height: 90%;
  overflow: scroll;
  z-index: 999;
  &__h1 {
    color: black;
    margin: 40px 0px;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  &__reSend {
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin: 30px 0px 10px 0px;
    &__btn {
      cursor: pointer;
      border-radius: 10px;
      border: 2px solid orange;
      background-color: white;
      padding: 10px;
      color: black;
      transition: all 400ms;
      &:hover {
        background-color: orange;
        transform: scale(1.05);
        color: white;
      }
      &__load {
        border-radius: 10px;
        border: 2px solid orange;
        background-color: white;
        padding: 0px 10px;
        display: flex;
        color: black;
        &__span {
          align-self: center;
        }
        &__arc {
          position: relative;
          bottom: 9px;
          margin: 18px 10px 0px 10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border-top: 1px solid black;
          border-bottom: 1px solid transparent;
          border-left: 1px solid black;
          border-right: 1px solid transparent;
          animation: load 2000ms both infinite ease-out;
          &__circle {
            position: relative;
            right: -13px;
            width: 5px;
            height: 5px;
            background-color: black;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .login {
    padding: 40px 20px;
    width: 300px;
    box-shadow: 2px 2px 2px 2px black;
  }
}

@media screen and (max-width: 768px) {
  .modalCloseEmail {
    width: 80%;
  }
}

@media screen and (max-width: 425px) {
  .modalCloseEmail {
    width: 95%;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}

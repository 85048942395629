.card {
  width: 100%;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  padding: 10px 0px 10px 10px;
  position: relative;
  display: flex;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.04);
  color: inherit;
  font: inherit;
  cursor: pointer;
  transition: all 400ms;
  &:hover {
    background-color: orange;
  }
  &:hover > .card__info {
    color: white;
  }
  &:hover > .card__icone {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(300deg)
      brightness(105%) contrast(101%);
  }
  &:hover > .card__info__icone {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(300deg)
      brightness(105%) contrast(101%);
  }
  &__load {
    width: 100%;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 0px 10px 10px;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    position: relative;
    color: inherit;
    font: inherit;
    display: flex;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.04);
  }
  &__icone {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 400ms;
  }
  &__info {
    margin-left: 60px;
    transition: all 400ms;
    width: 100%;
    &__name {
      text-align: left;
    }
    &__icone {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 400ms;
    }
    &__p {
      word-wrap: break-word;
      word-break: break-word;
      width: calc(100% - 70px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
    }
    &__p:focus,
    &__:hover {
      color: black;
    }
    &__p:focus:after,
    &__p:hover:after {
      content: attr(data-text);
      overflow: visible;
      text-overflow: initial;
      background: #fff;
      position: absolute;
      left: auto;
      top: auto;
      z-index: 999;
      border-radius: 5px;
      width: auto;
      max-width: 20rem;
      border: 1px solid #eaebec;
      padding: 0 0.5rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
      white-space: normal;
      word-wrap: break-word;
      display: block;
      color: black;
      margin-top: -1.25rem;
    }
  }
  /* &__arc {
    margin: 18px 10px 0px 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 1px solid black;
    border-bottom: 1px solid transparent;
    border-left: 1px solid black;
    border-right: 1px solid transparent;
    animation: load 2000ms both infinite ease-out;
    &__circle {
      position: relative;
      right: -13px;
      width: 5px;
      height: 5px;
      background-color: black;
      border-radius: 50%;
    }
  } */
  &__load {
    &__arc {
      position: absolute;
      right: 50px;
      top: 5px;
      margin: 18px 10px 0px 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border-top: 1px solid black;
      border-bottom: 1px solid transparent;
      border-left: 1px solid black;
      border-right: 1px solid transparent;
      animation: load 2000ms both infinite ease-out;
      &__circle {
        position: relative;
        right: -13px;
        width: 5px;
        height: 5px;
        background-color: black;
        border-radius: 50%;
      }
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(680deg);
  }
}

@media screen and (max-width: 425px) {
  .card {
    /* min-height: 60px; */
    height: 60px;
    &__info {
      margin-left: 40px;
      align-self: center;
      &__p {
        margin-right: 40px;
        word-wrap: break-word;
        text-align: left;
        overflow: hidden;
        width: 180px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &__arc {
      position: absolute;
      right: 50px;
      bottom: 18px;
    }
    &__load {
      height: 60px;
      &__info {
        margin-left: 40px;
        align-self: center;
        &__p {
          display: none;
        }
      }
      &__arc {
        display: none;
        position: absolute;
        right: 50px;
        bottom: 18px;
      }
    }
  }
}

.logo {
  background-color: rgb(41, 41, 41);
  margin: auto;
  width: 73px;
  &__link {
    position: relative;
    margin-top: 8px;
    left: 50%;
    transform: translateX(-50%);
    &__img {
      margin-top: 8px;
      cursor: pointer;
      width: 73px;
      height: 73px;
    }
  }
}
.div {
  box-sizing: border-box;
  overflow: scroll;
  height: calc(100% - 170px);
  padding: 20px 100px 20px 60px;
}

.header {
  position: fixed;
  box-shadow: 0px 1px 20px 0px aqua;
  width: 100%;
  z-index: 699;
  background-color: white;
  top: 0;
  display: flex;
  &__nocookie {
    width: 100%;
    background: red;
    padding: 20px 20px 20px 20px;
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    &__btn {
      cursor: pointer;
      border: none;
      background-color: orange;
      border-radius: 5px;
      transition: all 400ms;
      margin-left: 20px;
      padding: 5px;
      &:hover {
        transform: scale(1.05);
      }
      &:hover > &__img {
        transform: rotate(90deg);
      }
      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 400ms;
      }
    }
  }
  &__div {
    display: flex;
  }
  &__header {
    &__margin {
      margin-bottom: 100px;
    }
    &__margins {
      margin: 70px 0px 100px 0px;
    }
  }
  &__figure {
    display: flex;
    flex: 1;
    margin: auto 0px auto 20px;
    margin-top: auto;
    margin-bottom: auto;
    min-width: 200px;
  }
  &__burger {
    display: none;
    &__log {
      display: none;
    }
  }
  &__ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    align-items: center;
  }

  &__figcaption {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    position: relative;
    color: black;
    min-width: 200px;
  }

  &__logo {
    width: 80px;
    height: 80px;
  }

  &__nav {
    display: flex;
    flex: 3;
    justify-content: end;
    margin: auto 20px auto 0px;
    height: 10%;
    width: 60%;
  }
  &__free {
    animation: free 1s infinite;
  }
  &__a {
    font-size: 16px;
    color: black;
    cursor: pointer;
    margin-left: 30px;
    transition: all 400ms;
  }

  &__li {
    align-items: center;
    display: flex;

    &__hover {
      cursor: pointer;
      position: relative;
      top: 15px;
      padding-bottom: 30px;
    }
  }

  &__li:hover > &__a {
    color: orange;
    transform: scale(1.1, 1.1);
  }

  &__li:hover > .active {
    border-bottom: 2px solid orange;
  }

  &__li:hover > &__span {
    transform: rotate(90deg);
  }

  &__li:hover > &__ul__hidden {
    display: block;
    white-space: nowrap;
    padding-right: 40px;
  }

  &__ul {
    &__hidden {
      position: absolute;
      display: none;
      list-style-type: none;
      top: 35px;
      padding-inline-start: 0px;
      background-color: aqua;
      border-radius: 10px;
      padding: 10px;
      z-index: 999;
    }
  }

  &__span {
    font-size: 15px;
    margin-left: 7px;
    display: inline-block;
    margin-left: 16px;
    transform: rotate(0deg);
    transition: all 400ms;
    &:hover {
      transform: rotate(90deg);
    }
  }

  &__login {
    background-color: aqua;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
    margin-left: 30px;
    cursor: pointer;
    font-size: 16px;
    transition: all 1s;
    &:hover {
      box-shadow: 0px 2px 10px 1px orange;
    }
    &__active {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      opacity: 0.2;
    }
    &__load {
      width: 115px;
      height: 39px;
      background-color: aqua;
      color: white;
      border: none;
      border-radius: 10px;
      margin-left: 30px;
      cursor: pointer;
      font-size: 16px;
    }
  }

  &__log {
    margin: auto 20px auto 10px;
    width: 50px;
    height: 50px;
    &__container {
      background-color: rgb(41, 41, 41);
      color: black;

      position: absolute;
      //padding: 150px 50px 100px 40px;
      z-index: 900;
      height: 100vh;
      right: 0px;
      top: 0;
      width: 400px;
      transition: transform 800ms 0ms cubic-bezier(0.76, 0, 0.8, 1);
      &__show {
        z-index: 900;
        transform: translateX(0px);
      }
      &__hide {
        z-index: 0;
        transform: translateX(calc(100% + 390px));
      }
    }
    &__div {
      width: 50px;
      height: 50px;
      position: relative;
      z-index: 650;
      height: 50px;
      background-color: aqua;
      border-radius: 50%;
      border: none;
      cursor: pointer;
    }
    &__img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &__ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
      //height: calc(100% - 170px);
      height: 100%;
      min-height: 200px;
      max-height: 240px;
      justify-content: space-around;
      //overflow: scroll;
      //padding: 20px 100px 20px 60px;
      list-style-type: none;
    }
    &__li {
      font-size: 24px;
      color: white;

      &__show {
        animation: translateLink 1s ease-in-out;
      }
      &__2 {
        animation-delay: 50ms;
      }
      &__3 {
        animation-delay: 150ms;
      }
      &__4 {
        animation-delay: 200ms;
      }
      &__5 {
        animation-delay: 250ms;
      }

      &__link {
        cursor: pointer;
        position: relative;
        font-weight: 300;
      }
      &__point {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        position: absolute;
        left: -22px;
        top: 50%;
        transition: all 300ms 100ms ease-in-out;
        &__hide {
          transform: scale(0);
        }
        &__show {
          transform: scale(1) translateY(-50%);
        }
      }
    }
    &__btn {
      background-color: aqua;
      padding: 10px 20px;
      color: white;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin: auto;
      transition: all 400ms;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@keyframes translateLink {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0px);
  }
}

.active {
  border-bottom: 2px solid black;
}

.headerScroll {
  position: fixed;
  width: 100%;
  z-index: 5;
  top: 0;
  &__btn {
    background-color: white;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-radius: 0 0 10px 10px;
    padding: 5px 15px;
    margin-left: 30px;
    cursor: pointer;
    font-size: 16px;
    transition: all 1s;
    &:hover {
      box-shadow: 0px 2px 10px 1px orange;
    }
  }
  &__btnClose {
    background-color: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-radius: 0 0 10px 10px;
    padding: 5px 15px;
    margin-left: 30px;
    cursor: pointer;
    font-size: 16px;
    transition: all 1s;
    bottom: -20px;
  }
}

.line {
  &__show {
    position: fixed;
    height: 1px;
    z-index: 1;
    width: 100%;
    box-shadow: aqua 0px 20px 90px 10px;
  }
  &__hide {
    display: none;
  }
}

.main {
  position: relative;
  z-index: 999;
  top: 0px;
  right: 0px;
  &__top {
    position: relative;
    z-index: 999;
    top: -80px;
    right: 0px;
    &__test {
      position: relative;
      z-index: 999;
      top: 0px;
      right: 0px;
    }
  }
  &__test {
    transition: all 1s;
  }
}
@keyframes test {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.headerr {
  position: relative;
  z-index: 997;
  top: 0px;
  right: 0px;
  display: none;
  padding: 17px;
  &__log {
    display: block;
    position: relative;
    z-index: 998;
    top: 0px;
    right: 0px;
    padding: 17px;
    width: 50px;
    height: 50px;
    .button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: aqua;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }
    .burger {
      width: 100%;
      &::after,
      &::before {
        content: "";
        display: block;
        height: 2px;
        width: 40%;
        margin: auto;
        background-color: black;
        position: relative;
        transition: transform 0.3s;
      }

      &::after {
        top: -5px;
      }

      &::before {
        top: 5px;
      }
    }

    .burgerActive {
      &::after {
        transform: rotate(45deg);
        top: -2px;
        width: 60%;
        right: 0px;
      }

      &::before {
        transform: rotate(-45deg);
        top: 0px;
        width: 60%;
        right: 0px;
      }
    }
  }

  .button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: aqua;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  .burger {
    width: 100%;
    &::after,
    &::before {
      content: "";
      display: block;
      height: 2px;
      width: 40%;
      margin: auto;
      background-color: black;
      position: relative;
      transition: transform 0.3s;
    }

    &::after {
      top: -5px;
    }

    &::before {
      top: 5px;
    }
  }

  .burgerActive {
    &::after {
      transform: rotate(45deg);
      top: -2px;
      width: 60%;
      right: 0px;
    }

    &::before {
      transform: rotate(-45deg);
      top: 0px;
      width: 60%;
      right: 0px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .headerr {
    display: block;
  }
  .header {
    //display: block;
    opacity: 1;
    &__header {
      &__margin {
        margin-bottom: 100px;
      }
      &__margins {
        margin: 50px 0px 100px 0px;
      }
    }
    &__figure {
      display: flex;
      margin: auto 0px auto 20px;
      margin-top: auto;
      margin-bottom: auto;
      min-width: 200px;
    }

    &__burger {
      display: block;
      align-self: center;
      //position: absolute;
      right: 145px;
      top: 45px;
      &__log {
        display: block;
        align-self: center;
        //position: absolute;
        right: 65px;
        top: 45px;
      }
      &__line {
        display: block;
      }

      &__btn {
        width: 40px;
        height: 35px;
        background-color: aqua;
        border: none;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__ul {
      display: none;
      line-height: 30px;

      &__mobile {
        line-height: 30px;
        display: block;
      }

      &__hidden {
        position: unset;
        display: block;
        background-color: unset;
        margin-left: 15px;
        line-height: 30px;
      }
    }

    &__figcaption {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 16px;
      position: relative;
      color: black;
      min-width: 200px;
    }

    &__logo {
      width: 80px;
    }

    &__li {
      display: block;
    }

    &__nav {
      margin: auto 20px auto 0px;
      height: 10%;
      width: 60%;

      &__mobile {
        display: block;
      }
    }

    &__login {
      background-color: aqua;
      color: white;
      border: none;
      border-radius: 10px;
      padding: 10px;
      position: relative;
      z-index: 999;
      &__active {
        opacity: 0.05;
      }
    }

    &__log {
      /* position: absolute;
      top: 50%;
      transform: translateY(-50%); */
      z-index: 997;
      &__div {
        width: 50px;
        height: 50px;
        background-color: aqua;
        border-radius: 50%;
        cursor: pointer;
      }

      //&__ul {
      /* background-color: rgb(41, 41, 41);
        color: black;
        right: 0px;
        list-style-type: none;
        position: absolute;
        padding: 150px 50px 100px 40px; */
      //margin-top: 10px;
      //border-radius: 10px;
      //}
      &__li {
        font-size: 24px;
        color: white;
        &__link {
          position: relative;
        }
        &__point {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: white;
          position: absolute;
          position: absolute;
          left: -22px;
          top: 50%;
        }
      }
    }

    &__bar {
      border-bottom: 3px solid white;
      border-radius: 3px;
      width: 20px;
      display: flex;
      flex-direction: column;
    }

    &__cross {
      border-bottom: 3px solid white;
      border-radius: 3px;
      width: 25px;
      display: flex;
      flex-direction: column;
    }

    &__bar1 {
      margin-bottom: 3px;

      &__rotate {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotate(45deg) translate(-50%, -50%);
        transform-origin: 0% 0%;
      }
    }

    &__bar2 {
      margin-bottom: 3px;

      &__rotate {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotate(-45deg) translate(-50%, -50%);
        transform-origin: 0% 0%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    &__logo {
      width: 70px;
      height: 70px;
    }
    &__log {
      //position: relative;
      width: 45px;
      height: 45px;
      left: 20px;
      &__div {
        width: 45px;
        height: 45px;
      }
    }
  }
  .headerr {
    &__log {
      width: 45px;
      height: 45px;
      .button {
        width: 45px;
        height: 45px;
      }
    }
    .button {
      width: 45px;
      height: 45px;
    }
  }
  .logo {
    &__link {
      &__img {
        width: 65px;
        height: 65px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .header {
    &__figcaption {
      display: none;
    }
    &__figure {
      position: relative;
      min-width: unset;
      margin: auto 0px auto 10px;
    }
    &__login {
      right: -20px;
    }
    &__logo {
      width: 60px;
      height: 60px;
    }
    &__log {
      right: 80px;
      width: 40px;
      height: 40px;
      &__div {
        width: 40px;
        height: 40px;
      }
    }
    &__nocookie {
      padding: 10px 10px 10px 10px;
      &__btn {
        margin-left: 10px;
      }
    }
  }
  .headerr {
    &__log {
      width: 40px;
      height: 40px;
      .button {
        width: 40px;
        height: 40px;
      }
    }
    .button {
      width: 40px;
      height: 40px;
    }
  }
  .logo {
    margin: unset;
    &__link {
      position: unset;
      margin-left: 20px;
      &__img {
        width: 55px;
        height: 55px;
      }
    }
  }
}

@keyframes free {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
